<template>
  <div>
    <h4 class="uk-heading-line">
      Facility List
    </h4>
    <ul
      uk-tab
    >
      <li class="uk-active">
        <a href="#">Pulping Facility</a>
      </li>
      <li>
        <a href="#">Plantation</a>
      </li>
    </ul>

    <ul class="uk-switcher uk-margin">
      <li class="uk-active">
        <table-pulping-facility 
          :params="paramsTable"
        />
      </li>
      <li>
        <table-plantation
          :params="paramsTable"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import TablePulpingFacility from '@/components/globals/table/facility/master-table-facility-list/pulping-facility'
import TablePlantation from '@/components/globals/table/facility/master-table-facility-list/plantation'

export default {
  components: {
    TablePulpingFacility,
    TablePlantation
  },
  data() {
    return{
      paramsTable: {
        hulling_facility_id: this.$route.params.id
      }
    }
  }
}
</script>
